import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    darkTheme: true // Começa com o tema escuro
  },
  mutations: {
    toggleTheme(state) {
      state.darkTheme = !state.darkTheme
    }
  },
  actions: {
    toggleTheme({ commit }) {
      commit("toggleTheme")
    }
  },
  getters: {
    isDarkTheme: state => state.darkTheme
  }
})
