import Vue from "vue"
import App from "./App.vue"
import Vuetify from "vuetify/lib"
import "vuetify/dist/vuetify.min.css"
import store from "./store" // Importando o Vuex store

Vue.config.productionTip = false

Vue.use(Vuetify)

new Vue({
  vuetify: new Vuetify({
    theme: { dark: store.state.darkTheme } // Vincule ao estado do Vuex
  }),
  store,
  render: h => h(App)
}).$mount("#app")
